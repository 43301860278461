<template>
  <div>
    <v-row class="px-4 pt-4">
      <v-col col="12" md="3">
        <DataField
          :disabled="disableDataCriada"
          :data_sync.sync="ordem_servico.data_criada"
          :label="$tc('global.data-criacao')"
          hide_details
          obrigatorio
        />
      </v-col>
      <v-col col="12" md="3">
        <SearchComboboxClientes
          :readonly="disableClientes"
          :cliente_sync.sync="selectedCliente"
          :cliente_id="ordem_servico.cliente_id"
          :cliente_nome="ordem_servico.cliente_nome"
        />
      </v-col>
      <v-col col="12" md="12" v-if="selectedCliente" class="mt-0 pt-2">
        <div v-if="selectedCliente.endereco_principal">
          <div>
            <span class="text-subtitle-1 grey--text">
              {{ $tc("global.endereco") + ": " }}
            </span>
            <span class="text-body-1">
              {{
                selectedCliente.endereco_principal.rua +
                ", " +
                selectedCliente.endereco_principal.numero +
                " - " +
                selectedCliente.endereco_principal.bairro
              }}
            </span>
          </div>
        </div>
        <div v-else>{{ $tc("global.clienteSemEndereco") }}</div>
        <div>
          <span class="text-subtitle-1 grey--text">
            {{ $tc("global.telefone") + ": " }}
          </span>
          <span class="text-body-1">{{ selectedCliente.telefone }}</span>
          &nbsp;
          <span class="text-subtitle-1 grey--text"
            >{{ $tc("global.celular") + ": " }}
          </span>
          <span class="text-body-1">{{ selectedCliente.celular }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-4 pt-0">
      <v-col col="12" md="12" class="d-flex">
        <DataField
          :disabled="disableDataPrevisaoInicio"
          :data_sync.sync="ordem_servico.previsao_inicio"
          :label="$tc('global.previsaoInicio')"
          hide_details
          obrigatorio
          classes="mr-5"
        />
        <DataField
          :disabled="disableDataPrevisaoFim"
          :data_sync.sync="ordem_servico.previsao_fim"
          :label="$tc('global.previsaoFim')"
          hide_details
          obrigatorio
          classes="mr-5"
        />
        <DataField
          :disabled="disableDataProntaInstalacao"
          :data_sync.sync="ordem_servico.data_pronta_instalacao"
          :label="$tc('global.agendamento') + ' ' + $tc('global.servico')"
          hide_details
          obrigatorio
          classes="mr-5"
        />
        <DataField
          :disabled="disableDataInstalacao"
          :data_sync.sync="ordem_servico.data_instalacao"
          :label="$tc('global.inicioServico')"
          hide_details
          obrigatorio
          classes="mr-5"
        />
        <DataField
          :disabled="disableDataConclusao"
          :data_sync.sync="ordem_servico.data_conclusao"
          :label="$tc('global.fimServico')"
          hide_details
          obrigatorio
        />
      </v-col>
      <v-col cols="12" md="7">
        <ServicosPrestadosCombobox
          :readonly="disableServicosPrestados"
          :servico_sync.sync="selectedServicoPrestado"
          :servico_id="ordem_servico.servico_prestado_id"
        />
      </v-col>
      <v-col cols="12" md="1">
        <v-checkbox
          :disabled="disableGarantia"
          v-model="ordem_servico.garantia"
          :label="$tc('global.garantia')"
          hide-details
          class="mt-1"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="selectedMoeda"
          :items="moedas"
          :disabled="disableMoeda"
          label="Moeda"
          item-text="sigla"
          item-value="id_moeda"
          dense
          hide-details
          outlined
          return-object
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-combobox
          v-model="selectedLista"
          :items="listas_precos"
          :disabled="disableLista"
          :label="$tc('global.listadepreco')"
          item-text="descricao"
          item-value="id"
          dense
          hide-details
          outlined
        >
        </v-combobox>
      </v-col>
    </v-row>
    <OrdemServicoManualProdutos
      :disabled="disableProdutos"
      :selectedMoeda="selectedMoeda"
      :selectedLista="selectedLista"
      :depositoId.sync="deposito_id"
      :produtos.sync="produtos"
    />
    <OrdemServicoManualPrestadores
      :disabled="disablePrestadores"
      :selectedMoeda="selectedMoeda"
      :prestadores.sync="prestadores"
    />
    <span class="text-subtitle-1 ml-4 mb-1">{{ $tc("global.maoDeObra") }}</span>
    <v-row class="px-4 pt-0 mb-2">
      <v-col col="6" md="6">
        <v-text-field
          :disabled="disableMaoDeObra"
          v-model="descricaoMaoDeObra"
          :label="$tc('global.descricao')"
          hide-details
          outlined
          dense
          rows="2"
        >
        </v-text-field>
      </v-col>
      <v-col col="2" md="2">
        <v-text-field
          :disabled="disableMaoDeObra"
          v-model="valorMaoDeObra"
          :placeholder="$tc('global.valorMaoDeObra')"
          hide-details
          dense
          solo
          flat
          outlined
          :append-icon="invalidPrecoItem ? 'mdi-lock-alert-outline' : ''"
          @click:append="openDialogAuth(false)"
          :error-messages="erroPrecoItem"
          ref="valorMaoDeObra"
          v-currency="vCurrencyOptions"
        >
        </v-text-field>
      </v-col>
      <v-col class="flex mt-2 align-center">
        <div class="text-subtitle-2 grey--text text-uppercase">
          {{ $tc("global.totalGeral") + ": " }}
          
          <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'" class="text-subtitle-2 black--text text-uppercase">
            {{ totalGeralComputed | guarani }}
          </span>
          <span v-else class="text-subtitle-2 black--text text-uppercase">
            {{ totalGeralComputed | currency }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-4 mb-2">
      <v-col>
        <v-file-input
          required
          suffix="Max 100MB"
          :messages="
            $tc('global.formatosPermitidos') +
            ' : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt.'
          "
          v-model="filesForm"
          :label="$tc('global.termoEntregaAssinado')"
          :placeholder="$tc('global.anexos')"
          accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
          multiple
          class="mb-2 mx-2"
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip
              small
              label
              close
              close-icon="mdi-delete"
              color="primary"
              @click:close="files=[]"
            >
              {{ text ? text : files.length > 0 ? files[0].nome : "" }}
            </v-chip>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchLPCompletas } from "@/api/listas-precos/listasprecos.js";
export default {
  name: "FormOrdemServicoManual",

  props: {
    ordem_servico_id: {
      type: [String, Number],
    },
    ordem_servico: {
      type: Object,
      default: () => ({}),
    },
    files: {
      type: Array,
      default: () => [],
    },
    podeEnviarCaixa: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    SearchComboboxClientes: () =>
      import(
        "@/components/fields/combobox/clientes/SearchComboboxClientes.vue"
      ),
    ServicosPrestadosCombobox: () =>
      import(
        "@/components/fields/combobox/servicosPrestados/ServicosPrestadosCombobox.vue"
      ),
    OrdemServicoManualProdutos: () =>
      import("./OrdemServicoManualProdutos.vue"),
    OrdemServicoManualPrestadores: () =>
      import("./OrdemServicoManualPrestadores.vue"),
  },

  data() {
    return {
      filesForm: [],
      selectedCliente: null,
      selectedServicoPrestado: null,
      selectedMoeda: null,
      selectedLista: null,
      selectedDeposito: null,
      disableAll: false,
      deposito_id: null,
      listas_precos: [],
      produtos: [],
      prestadores: [],
      maoDeObra: null,
      isVendaItemEditing: false,
      indexVendaItemEditing: null,
      invalidPrecoItem: false,
      erroPrecoItem: "",
      valorMaoDeObra: null,
      descricaoMaoDeObra: null,
      totalProdutos: null,
      totalPrestadores: null,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),
    podeEnviarCaixaComputed(){
      if (this.files.length > 0 && this.ordem_servico.data_criada && this.ordem_servico.previsao_inicio 
      && this.ordem_servico.previsao_fim && this.ordem_servico.data_pronta_instalacao
      && this.ordem_servico.data_instalacao && this.ordem_servico.data_conclusao && this.ordem_servico.status == 5 && this.ordem_servico.enviado_caixa == null)
        return true;
      return false; 
    },
    disableDataCriada() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableClientes() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableDataPrevisaoInicio() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableDataPrevisaoFim() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableDataProntaInstalacao() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableDataInstalacao() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableDataConclusao() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableServicosPrestados() {
      if (this.disableAll)
        return true;
      return false;
    },
    disablePrestadores() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableProdutos() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableGarantia() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableMaoDeObra() {
      if (this.disableAll)
        return true;
      return false;
    },
    disableMoeda() {
      return this.produtos.length > 0 || this.prestadores.length > 0 || this.disableAll;
    },
    disableLista() {
      return this.produtos.length > 0 || this.disableAll;
    },
    totalGeralComputed() {
      return (
        Number(this.totalProdutosComputed) +
        Number(this.totalPrestadoresComputed) +
        Number(this.$ci.parse(this.valorMaoDeObra, this.vCurrencyOptions))
      );
    },
    totalProdutosComputed() {
      return this.produtos.reduce((acc, item) => {
        return Number(acc) + Number(item.preco) * Number(item.qtde);
      }, 0);
    },
    totalPrestadoresComputed() {
      return this.prestadores.reduce((acc, item) => {
        return Number(acc) + Number(item.valor);
      }, 0);
    },
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },
  },

  watch: {
    podeEnviarCaixaComputed(val){
      this.$emit("podeEnviarCaixa", val);
    },
    valorMaoDeObra(val) {
      if (this.ordem_servico_id){
      this.ordem_servico.mao_de_obra.valor = this.$ci.parse(
        val,
        this.vCurrencyOptions
      );
      }
      this.ordem_servico.valor_mao_de_obra = this.$ci.parse(
        val,
        this.vCurrencyOptions
      );
    },
    filesForm(val) {
      this.ordem_servico.files = val;
    },
    descricaoMaoDeObra(val) {
      this.ordem_servico.descricao_mao_de_obra = val;
    },
    deposito_id(val) {
      this.ordem_servico.deposito_id = val;
    },
    totalGeralComputed(val) {
      this.ordem_servico.valor = val;
    },
    produtos() {
      this.ordem_servico.produtos = this.produtos;
    },
    prestadores() {
      this.ordem_servico.prestadores = this.prestadores;
    },
    selectedCliente() {
      if (this.selectedCliente && this.selectedCliente.lista_preco_id) {
        this.selectedLista = this.listas_precos.find(
          (lista) => lista.id === this.selectedCliente.lista_preco_id
        );
      }
      this.ordem_servico.cliente_id = this.selectedCliente.id;
    },
    selectedServicoPrestado() {
      if (this.selectedServicoPrestado) {
        this.ordem_servico.servico_prestado_id = this.selectedServicoPrestado.id;
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.ordem_servico.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedLista() {
      if (this.selectedLista) {
        this.ordem_servico.lista_preco_id = this.selectedLista.id;
      }
    },
  },

  methods: {
    async getListasPrecos() {
      const response = await fetchLPCompletas();
      this.listas_precos = response;
      this.selectedLista = this.listas_precos[0];
    },
  },
  async mounted() {
    if (this.podeEnviarCaixaComputed)
      this.$emit("podeEnviarCaixa", true);
    if (this.ordem_servico.prestadores) {
      this.prestadores = this.ordem_servico.prestadores;
      this.prestadores.forEach((prestador) => {
        prestador.id = prestador.fornecedor_id;
        delete prestador.fornecedor_id;
      });
    }
    if (this.ordem_servico.produtos) {
      this.produtos = this.ordem_servico.produtos;
    }
    this.selectedMoeda = this.moedas.find(
      (moeda) => moeda.id_moeda === this.moeda_empresa
    );
    await this.getListasPrecos();
    if (this.ordem_servico.mao_de_obra && this.ordem_servico.mao_de_obra.length > 0) {
      this.$ci.setValue(
        this.$refs.valorMaoDeObra,
        this.ordem_servico.mao_de_obra[0].valor
          ? this.ordem_servico.mao_de_obra[0].valor
          : 0
      );
      this.descricaoMaoDeObra = this.ordem_servico.mao_de_obra[0].descricao;
    }
    if (this.ordem_servico.enviado_caixa !== null && this.ordem_servico.enviado_caixa !== undefined) {
      this.disableAll = true;
    }
    if (this.files){
      this.filesForm = this.files;
    }
  },
};
</script>
